import {Router, RouteComponentProps} from '@reach/router'
import styled from '@emotion/styled'

import {ExtraSmallBody} from 'src/components/text'
import {useResourcePageQuery} from 'src/graphql-generated'
import Loading from 'src/components/Loading'
import ResourcePageTemplate from 'src/components/ResourcePageTemplate'

const PreviewNotice = styled.div({
  position: 'fixed',
  bottom: 0,
  right: 0,
  background: 'tomato',
  padding: '0.2rem',
})

const Preview: React.FunctionComponent<
  RouteComponentProps<{
    resourcePageId: string
  }>
> = ({resourcePageId}) => {
  const {data, loading, error} = useResourcePageQuery({
    variables: {id: resourcePageId || '1'},
  })

  if (loading) {
    return <Loading size="medium" />
  }

  return data && data.resourcePage ? (
    <>
      <ResourcePageTemplate
        resourcePage={
          {
            heroFile: null,
            ...data.resourcePage,
          } as any
        }
      />
      <PreviewNotice>
        <ExtraSmallBody>PREVIEW MODE</ExtraSmallBody>
      </PreviewNotice>
    </>
  ) : (
    <div>
      {error ? error.message : `ResourcePage ${resourcePageId} not found.`}
    </div>
  )
}

const ResourcePagePreview = () => (
  <Router basepath="/resource-page-preview">
    <Preview path={'/:resourcePageId'} />
  </Router>
)

export default ResourcePagePreview
